import Vue from 'vue';
import customModal from './modal.vue';

const modal = Vue.extend(customModal);
// console.log(new modal({data}))
let instance = new modal({}).$mount();
document.body.appendChild(instance.$el);
customModal.confirm = function (option) {
    Object.assign(instance, option,{type:'confirm'}); //这个要合并的type属性是根据调用方法来确定类型的，对应modal.vue中要设置的type,这个地方是必填项，后面扩展的话自己考虑，类似于iview中success、error、warming等类型，用来区分
    instance.isShow = true;
}
customModal.info = function (option) {
    Object.assign(instance, option,{type:'info'});
    instance.isShow = true;
}
export default customModal;
