<template>
  <div id="app">
    <!-- 客服 -->
     <!-- <a href="https://t.me/klubaaa" target="_blank" rel="noopener noreferrer" class="kefu-box">
      <img src="/img/home/kefu-icon.png" alt="">
     </a> -->
      <router-view></router-view>
      <Footer/>
  </div>
</template>

<script>
import Footer from './common/Footer'
export default {
  name: 'app',
  components: {
    Footer
  },
  data() {
    return {
      status:0
    };
  },
  methods: {
    getBaseInfo(){
      this.$http({
        method: 'get',
        url: 'base_info'
      }).then(res=>{
        this.$store.commit('setBaseInfoValue', res.data);
      })
    }

  },
  created(){
    this.getBaseInfo();
  }
}
</script>

<style>
body .van-toast {
  font-size: 30px;
  padding: 30px;
  line-height: 50px;
  width: 45%;
}
body .van-toast .van-toast__icon {
  font-size: 50px;
}
*, :after, :before {
  box-sizing: border-box;
}
/* .kefu-box{
  width: 100px;
  height: 100px;
  position: fixed;
  top: 40%;
  right: 0px;
  z-index: 999;
  background: red;
}
.kefu-box img{
  width: 100%;
  display: block;
} */
</style>
