<template>
  <div class="mine page">
    <div class="page-bg"></div>
    <div class="wrapper">
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh" pulling-text="Gulir ke bawah untuk menyegarkan..."
        loosing-text="Lepaskan untuk menyegarkan..." loading-text="Muat ulang">
        <div class="header">
          <van-nav-bar class="nav-bar">
            <template #right>
              <van-icon name="setting-o" @click="showSetting()" color="#fff" />
            </template>
          </van-nav-bar>
          <div class="user-wrapper" @click="doLogin()">
            <van-image round class="user_img" :src="this.userInfo.header_img" error-icon="img/mine/avatar.png">
              <template v-slot:loading>
                <van-loading type="spinner" />
              </template>
            </van-image>
            <div class="login-content">
              <p class="login-btn">{{ !this.userInfo.id ? $t('setting.pleaseIn') : this.userInfo.username }}</p>
              <!-- <p class="login-label">IP:{{ this.userInfo.ip }}</p> -->
            </div>
          </div>
        </div>
        <div class="content">
          <div class="finance">
            <div class="finance-item" @click="recharge()">
              <van-icon class="icon" style="" name="peer-pay" />
              <span class="text">{{ $t("my.recharge") }}</span>
            </div>
            <div class="line"></div>
            <div class="finance-item" @click="doWithdrawal()">
              <van-icon class="icon" name="idcard" />
              <span class="text">{{ $t("my.withdraw") }}</span>
            </div>
          </div>
          <div v-if="this.userInfo.money" class="wallet">
            <div class="part-1 van-hairline--bottom">
              <p class="flex-1 font-28 font-primary-color">{{ $t("my.my_balance") }}</p>
              <!-- <span class="font-28 font-gray">{{$t("my.detail")}}</span> -->
              <!-- <van-icon class="font-gray" style="font-size: 28px" name="arrow" /> -->
            </div>
            <div class="part-2">
              <p class="balance van-ellipsis">{{ ynmony | numyn }}</p>
              <span class="font-28 font-gray">{{ $t("my.balance") }}({{ $t("reservation.unit") }})</span>
              <div class="refresh-btn" @click="refresh()"><van-icon name="replay" /></div>
            </div>
            <div class="part-2">
              <p class="balance van-ellipsis">{{ integral }}</p>
              <span class="font-28 font-gray">Poin (skor penuh adalah 10,0 poin)</span>
              <div class="refresh-btn" @click="refresh()"><van-icon name="replay" /></div>
            </div>
          </div>
          <div :style="{ marginTop: menu_top + 'px' }" class="menu">
            <div class="menu-item" @click="$router.push({ path: '/Personalreport' });">
              <van-image class="menu-item-icon" src="img/mine/baobiao.svg">
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
              <span class="menu-item-label">{{ $t("my.my_statement") }}</span>
            </div>
            <div class="menu-item" @click="exit()">
              <van-image class="menu-item-icon" src="img/mine/mingxi.svg">
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
              <span class="menu-item-label">{{ $t("my.account_detail") }}</span>
            </div>
            <div class="menu-item" @click="$router.push({ path: '/GameRecord' });">
              <van-image class="menu-item-icon" src="img/mine/youxi.svg">
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
              <span class="menu-item-label">{{ $t("my.task_record") }}</span>
            </div>
            <div class="menu-item" @click="$router.push({ path: '/Infomation' });">
              <van-image class="menu-item-icon" src="img/mine/user.svg">
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
              <span class="menu-item-label">{{ $t("my.personal_center") }}</span>
            </div>
            <div class="menu-item" @click="toService()">
              <van-image class="menu-item-icon" src="img/mine/kefu_1.svg">
                <template v-slot:loading>
                  <van-loading type="spinner" />
                </template>
              </van-image>
              <span class="menu-item-label">{{ $t("my.online_service") }}</span>
            </div>
          </div>
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userInfo: {
      },
      ynmony: 0,
      integral: 0,
      menu_top: 40,
      isLoading: false,
    };
  },
  mounted() {
    setInterval(() => {
      if (localStorage.getItem('token')) {
        this.getUserInfo()
      }
    }, 5000)
  },
  methods: {
    refresh() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
        if (localStorage.getItem('token')) {
          this.$toast(this.$t("reservation.refresh"));
        } else {
          this.$router.push({ path: '/Login' })
        }
      }, 500);
    },

    exit() {
      this.$toast(this.$t("my.finish_task"));
    },
    showSetting() {
      if (localStorage.getItem('token')) {
        this.$router.push({ path: '/Setting' })
      } else {
        this.$router.push({ path: '/Login' })
      }
    },
    toNotice() {
      if (localStorage.getItem('token')) {
        this.$router.push({ path: '/Notice' })
      } else {
        this.$router.push({ path: '/Login' })
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;
        if (localStorage.getItem('token')) {
          this.getUserInfo();
          this.$toast(this.$t("reservation.refresh"));
        } else {
          this.$router.push({ path: '/Login' })
        }
      }, 500);
    },
    doLogin() {
      if (localStorage.getItem('token')) {
        this.$router.push({ path: '/Infomation' });
      } else {
        this.$router.push({ path: '/Login' })
      }
    },
    doPay() {
      this.$router.push({
        name: 'Recharge',
        params: {
          'balance': this.userInfo.money
        }
      })
    },
    doWithdrawal() {
      this.$http({
        method: 'get',
        url: 'user_get_bank'
      }).then(res => {
        if (res.data.is_bank) {
          this.$router.push("withdraw");
        } else {
          this.$router.push("Setbank");
          this.$toast.fail(this.$t("setting.set_bank"));
        }
      })
    },
    toService() {
      // this.$toast("Silakan hubungi layanan pelanggan");
      window.open('https://t.me/klubaaa', '_blank')
    },
    recharge() {
      this.$router.push({ path: '/Recharge?balance=' + this.ynmony })
    },
    getUserInfo() {
      this.$http({
        method: 'get',
        url: 'user_info'
      }).then(res => {
        if (res.code === 200) {
          console.log(res.data)

          this.userInfo = res.data;
          this.ynmony = new Number(this.userInfo.money);
          this.integral = this.userInfo.integral;
          this.menu_top = 15;
          if (this.userInfo.status !== 1) {
            this.$toast(this.$t("video.account_out"));
            localStorage.clear()
            this.$router.push({ path: '/Login' })
          }
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      })
    },
  },
  created() {
    if (localStorage.getItem('token')) {
      this.getUserInfo();
      this.userInfo.gd = "img/mine/avatar.png";

    } else {
      this.userInfo.username = this.$t("setting.log_reg");
      this.userInfo.ip = this.$t("setting.more_service");
      this.userInfo.gd = "img/mine/avatar.png";
    }
  },
  filters: {
    numyn: (val) => {
      val = '' + val // 转换成字符串
      let int = val
      int = int.split('').reverse().join('') // 翻转整数
      let temp = '' // 临时变量
      for (let i = 0; i < int.length; i++) {
        temp += int[i]
        if ((i + 1) % 3 === 0 && i !== int.length - 1) {
          temp += ',' // 每隔三个数字拼接一个逗号
        }
      }
      temp = temp.split('').reverse().join('') // 加完逗号之后翻转
      return temp // 返回
    }
  },
};

</script>

<style scoped>
.page {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  background-color: #f2f2f5;
}

.van-pull-refresh {
  height: 100%;
}

.mine {
  position: relative;
  bottom: 10px;
  background: #f2f2f5;
  overflow: auto;
}

.mine .wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.nav-bar {
  background: linear-gradient(90deg, #fbbebe, #f698ff);
}

.mine .header {
  background: linear-gradient(90deg, #fbbebe, #f698ff);
  padding-bottom: 100px;
}

::v-deep .van-nav-bar__content {
  height: 100px;
}

::v-deep .van-hairline--bottom::after {
  border-bottom-width: 0px;
}

.mine .header .van-nav-bar .van-icon {
  font-size: 45px;
}

.mine .header .user-wrapper {
  display: flex;
  align-items: center;
  margin: 0px 40px 0px 40px;

}

.mine .user_img {
  height: 130px;
  width: 130px;
}

::v-deep .van-loading__spinner {
  height: 50px;
  width: 50px;
}

::v-deep .van-image__error-icon {
  font-size: 70px;
}

.mine .header .user-wrapper .login-content {
  flex: 1;
  margin-left: 30px;
}

.mine .header .user-wrapper .login-content .login-btn {
  display: inline-block;
  font-size: 40px;
  line-height: 0px;
  color: #fff;
}

.mine .header .user-wrapper .login-content .login-label {
  margin-top: -13px;
  font-size: 28px;
  color: hsla(0, 0%, 100%, .6);
}

.mine .page-bg {
  height: 500px;
  background: linear-gradient(90deg, #000000, #5990ea);
}

.mine .content {
  position: relative;
  padding: 10px 30px 30px;
  min-height: 500px;
  background-color: #f2f2f5;
}

::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #ffffff;
  font-size: 35px;
}

.mine .wrapper .content .finance {
  position: absolute;
  display: flex;
  align-items: center;
  top: -55px;
  left: 30px;
  right: 30px;
  height: 120px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 1.5px 1px 0 #e4e4e7;
}

.mine .wrapper .content .finance .line {
  width: 3px;
  height: 40px;
  background-color: #ccc;
}

.mine .wrapper .content .finance .finance-item {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.mine .wrapper .content .finance .finance-item .text {
  margin-left: 30px;
  font-size: 30px;
  color: #000000;
  font-weight: 500;
}

.mine .wrapper .content .finance .finance-item .icon {
  font-size: 50px;
  color: #ff9898dd;
}

.mine .wrapper .content .menu {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 1.5px 1px 0 #e4e4e7;
}

.mine .wrapper .content .menu .menu-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 50%;
  height: 130px;
}

.mine .wrapper .content .menu .menu-item .menu-item-label {
  max-width: 236px;
  font-size: 30px;
  color: #000000;
  font-weight: 500;
}

.mine .wrapper .content .menu .menu-item .menu-item-icon {
  margin: 25px;
  width: 60px;
  height: 60px;
  -o-object-fit: contain;
  object-fit: contain;
}

.mine .wrapper .content .wallet {
  margin-top: 80px;
  padding: 0 30px;
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 1.5px 1px 0 #e4e4e7;
}

.mine .wrapper .content .wallet .part-1 {
  display: flex;
  align-items: center;
  height: 100px;
}

.mine .wrapper .content .wallet .font-primary-color {
  color: #000000;
}

.font-gray {
  color: #868686;
}

.mine .wrapper .content .wallet .part-2 {
  display: flex;
  align-items: center;
  height: 120px;
}

.mine .wrapper .content .wallet .part-2 .balance {
  flex: 1;
  font-size: 40px;
  color: #000000;
  font-weight: 700;
}

.mine .wrapper .content .wallet .van-hairline--bottom::after {
  border-bottom-width: 3px;
}

.mine .wrapper .content .wallet .part-2 .refresh-btn {
  margin-left: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  font-size: 30px;
  border-radius: 50%;
  color: #ffffff;
  background-color: #000000;
}
</style>
